export default [
  {
    header: "Брокерские счета",
  },
  {
    title: "Обзор",
    route: "inv-overview",
    icon: "EyeIcon",
    resource: "Investments",
    action: "read",
  },
  // {
  //   title: "Аналитика",
  //   route: "inv-analytics",
  //   icon: "PieChartIcon",
  //   resource: "Investments",
  //   action: "read",
  // },
  {
    title: "Сделки",
    route: "inv-transactions",
    icon: "AlignJustifyIcon",
    resource: "Investments",
    action: "read",
  },
  // {
  //   title: "Ребалансировка",
  //   route: "inv-rebalancing",
  //   icon: "ShuffleIcon",
  //   resource: "Investments",
  //   action: "read",
  // },
  {
    title: "Портфели",
    route: "inv-portfolios",
    icon: "BriefcaseIcon",
    resource: "Investments",
    action: "read",
  },
  {
    header: "Информация",
  },
  {
    title: "Ценные бумаги",
    route: "inv-securities",
    icon: "LayersIcon",
    resource: "Investments",
    action: "read",
  },
  // {
  //   title: 'Фин. отчетность',
  //   route: 'inv-fin-statement',
  //   icon: 'FileTextIcon',
  //   resource: 'Investments',
  //   action: 'read',
  // },
  {
    title: "Дох. облигации",
    route: "inv-bond-yeld",
    icon: "PercentIcon",
    resource: "Investments",
    action: "read",
  },
];
